import { useEffect, useRef, useState } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import GET_LEARNING_GOAL from './getLearningGoal.graphql'
import { useContainer } from 'unstated-next'
import { AppState } from '../state/AppState'
import UPDATE_LEARNING_GOAL from './updateLearningGoal.graphql'

export const useLearningGoal = ({ _id }) => {
  const client = useApolloClient()
  const [learningGoal, setLearningGoal] = useState({})
  const [wordList, setWordList] = useState(null)
  const [loading, setLoading] = useState(true)
  const { yearGroup } = useContainer(AppState)
  const [canSave, setCanSave] = useState(false)
  const originalLearningGoalRef = useRef(null)

  useEffect(() => {
    fetch()
  }, [yearGroup])

  const fetch = async () => {
    setLoading(true)
    const { data } = await client.query({
      query: GET_LEARNING_GOAL,
      variables: { _id, skip: 0, limit: 0, yearGroup },
      fetchPolicy: 'network-only'
    })
    setLearningGoal(data.curriculumLearningGoal)
    setWordList(data.curriculumLearningGoal.wordList)
    originalLearningGoalRef.current = data.curriculumLearningGoal
    setLoading(false)
  }

  const setTitle = (value) => {
    setCanSave(value !== originalLearningGoalRef.current?.title)
    setLearningGoal(l => ({ ...l, title: value }))
  }

  const setExample = (value) => {
    setCanSave(value !== originalLearningGoalRef.current?.example)
    setLearningGoal(l => ({ ...l, example: value }))
  }

  const updateLearningGoal = async () => {
    if (!canSave) return
    let payload = { title: learningGoal.title }
    if (wordList) {
      payload.wordListId = wordList.id
    }
    await client.mutate({
      mutation: UPDATE_LEARNING_GOAL,
      variables: {
        learningGoalId: learningGoal._id,
        input: payload
      }
    })
    setCanSave(false)
  }

  useEffect(() => {
    if ((wordList !== null && wordList.id !== originalLearningGoalRef.current?.wordList?.id) || (wordList === null && learningGoal.wordList)) {
      setCanSave(true)
    } else {
      setCanSave(false)
    }
  }, [wordList])

  return { learningGoal, setTitle, setExample, loading, setWordList, wordList, canSave, updateLearningGoal }
}
