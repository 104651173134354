import React from 'react'
import Layout from '../components/layout'
import LearningGoal from '../components/LearningGoal'

const LearningGoalPage = ({ id }) => (
  <Layout>
    <LearningGoal id={id} />
  </Layout>
)

export default LearningGoalPage
