import { useApolloClient } from '@apollo/react-hooks'
import { TextInput } from 'grommet'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { debounce } from '../../lib/debounce'
// @ts-ignore
import FETCH_WORD_LISTS from './fetchWordLists.graphql'

interface IProps {
  onSelected: (wordList: WordList) => void
}

interface WordList {
  id: string
  name: string
}

const Wrapper = styled.div`
  position: relative;
  margin: 15px 0;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.5);

`

const Results = styled.div`
  position: absolute;
  width: 100%;
  top: 100%;
  background-color: #fff;
  z-index: 1;
`

const Result = styled.div`
  padding: 8px 10px;
  cursor: pointer;
`

const WordListFinder = (props: IProps) => {
  const DEBOUNCE_TIME = 600

  const [wordLists, setWordLists] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const client = useApolloClient()
  const [visibleResults, setVisibleResults] = useState(false)

  const fetchWordLists = useCallback(async (searchValue) => {
    const { data } = await client.query({
      query: FETCH_WORD_LISTS,
      context: { clientName: 'content' },
      variables: {
        first: 5,
        search: searchValue
      }
    })
    setWordLists(data.wordLists)
  }, [])

  useEffect(() => {
    if (wordLists.length > 0) {
      setVisibleResults(true)
    }
  }, [wordLists])

  useEffect(() => {
    if (searchValue !== '') {
      debouncedFetchWordLists(searchValue)
    }
  }, [searchValue])

  const selectWordList = (wordList) => {
    setVisibleResults(false)
    props.onSelected(wordList)
    setSearchValue('')
  }

  const debouncedFetchWordLists = useCallback(debounce(fetchWordLists, DEBOUNCE_TIME)[0], [])

  return <Wrapper>
    <TextInput placeholder='Find a word list' value={searchValue} onChange={(e) => setSearchValue(e.target.value) } />
    { visibleResults && <>
      <Overlay  onClick={() => setVisibleResults(false)}/>
      <Results onClick={(e) => e.stopPropagation()}>
        { wordLists.map(w => <Result key={w.id} onClick={() => selectWordList(w)}>{w.name}</Result>) }
      </Results>
      </>
    }
  </Wrapper>
}

export default WordListFinder
