import React, { useContext } from 'react'
import { Wrapper, Title, Challenges, Challenge, NewChallenge, WordList, Actions, Field } from './index.styles'
import { useLearningGoal } from './useLearningGoal'
import { Add } from 'grommet-icons'
import ModalContext from '../Context/ModalContext'
import ChallengePreview from '../ChallengePreview'
import CreateChallengeModal from '../CreateChallengeModal'
import { nameForChallengeType } from '../CurriculumEditor/ChallengesList/TypeFilters'
import ChallengeLangControl from '../ChallengeLangControl'
import Loader from '../UI/Loader'
import WordListFinder from '../WordListFinder'
import { Button, TrashWhite } from 'nzk-react-components'
import { TextInput } from 'grommet'

const LearningGoal = ({ id }) => {
  const { learningGoal, setTitle, setExample, loading, wordList, setWordList, canSave, updateLearningGoal } = useLearningGoal({ _id: id })
  const modal = useContext(ModalContext)

  const openChallengePreview = (_id) => {
    modal.closeAll()
    modal.open(<ChallengePreview _id={_id} />)
  }

  const openChallengeCreationModal = () => {
    modal.open(<CreateChallengeModal learningGoalId={id} />)
  }

  function extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  if (loading) return <Loader />
  
  return <Wrapper>
    <Actions>
      { canSave && <Button theme='confirm' size='x-small' onClick={updateLearningGoal}>Save</Button> }
    </Actions>
    <Title>
      <TextInput placeholder='Title' value={learningGoal.title} onChange={(e) => {
        setTitle(e.target.value)
      }} />
    </Title>
    <Field>
      <div className='name'>Example</div>
      <TextInput placeholder='Example' value={learningGoal.example} onChange={(e) => {
        setExample(e.target.value)
      }} />
    </Field>
    <WordList>
      <h3>Word list</h3>
      { !wordList
        ? <WordListFinder onSelected={(w) => setWordList(w)} />
        : <div className='current'>
            <div>
              <Button round theme='red' size='x-small' onClick={() => setWordList(null)}>
                <TrashWhite />
              </Button>
            </div>
            <div>{wordList?.name}</div>
          </div>
      }
    </WordList>
    <Challenges>
      <NewChallenge onClick={openChallengeCreationModal}><Add color='#fff' size='large' /></NewChallenge>
      { learningGoal.challenges.map((challenge) =>
        <Challenge key={challenge._id} type={challenge.type} onClick={() => openChallengePreview(challenge._id)}>
          <div>{ nameForChallengeType(challenge.type) }</div>
          <div>{ extractContent(challenge.explanation) }</div>
          <div>
            <ChallengeLangControl _id={challenge._id} us={challenge.us} uk={challenge.uk} />
          </div>
        </Challenge>)
      }
    </Challenges>
  </Wrapper>
}

LearningGoal.propTypes = {

}

LearningGoal.defaultProps = {

}

export default LearningGoal
